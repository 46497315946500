import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import GameExpansionPaper from "./GameExpansionPaper";
import GameDetails from "./GameDetails";

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
    marginBottom: 12,
  },
}));

const GameGridCard = ({gameInfo}) => {
  const classes = useStyles();

  return(
    <Card className={classes.card}>
      <CardContent>
        <GameDetails gameInfo={gameInfo} />
        {
          gameInfo.expansions !== null
          ? gameInfo.expansions.map(game => {
            return(<GameExpansionPaper gameInfo={game} />);
          })
          : undefined
        }
      </CardContent>
    </Card>
  )
};

GameGridCard.propTypes = {
  gameInfo: PropTypes.object.isRequired
};


export default GameGridCard;
