import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  pos: {
    marginBottom: 12,
  },
}));

const GameDetails = ({gameInfo}) => {
  const classes = useStyles();

  return(
    <React.Fragment>
      <Typography className={classes.pos} variant="h6">
        {gameInfo.title}
      </Typography>
      <Typography variant="body1" component="p">
        Players: <b>{gameInfo.players[0]}
        {
          gameInfo.players[1] !== undefined
            ? ` - ${gameInfo.players[1]}`
            : undefined
        }
        </b>
        <br/>
        Play time: <b>{gameInfo.playTime[0]} - {gameInfo.playTime[1]} minutes</b>
        <br/>
        Complexity (out of 5): <b>{gameInfo.weight}</b>
      </Typography>
    </React.Fragment>
  )
};

GameDetails.propTypes = {
  gameInfo: PropTypes.object.isRequired
};

export default GameDetails;
