import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
    marginBottom: 12,
  },
}));

const GameGridTitle = ({title}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography align="center" variant="h4" component="h2">
          {title}
        </Typography>
      </CardContent>
    </Card>
    )
};

GameGridTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default GameGridTitle;