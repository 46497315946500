import React from "react";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import GameDetails from "./GameDetails";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 12,
    padding: theme.spacing(2),
  },
}));

const GameExpansionPaper = ({gameInfo}) => {
  const classes = useStyles();

  return(
    <Paper elevation={3} className={classes.paper}>
      <GameDetails gameInfo={gameInfo} />
    </Paper>
  )
};

GameExpansionPaper.propTypes = {
  gameInfo: PropTypes.object.isRequired
};

export default GameExpansionPaper;