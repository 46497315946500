import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }
}));

const GameGridLayout = ({children}) => {
  const classes = useStyles();

  return(
    <Box className={classes.root}>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </Box>
  )
};

export default GameGridLayout;