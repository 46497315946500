import React from 'react';
import Layout from "../components/Layout";
import Grid from '@material-ui/core/Grid';
import GameGridLayout from "../components/GameGridLayout";
import GameGridTitle from "../components/GameGridTitle";
import GameGridCard from "../components/GameGridCard";
import {graphql, useStaticQuery} from "gatsby";
import SiteHelmet from "../components/SiteHelmet";

const Boardgames = () => {
  const data = useStaticQuery(
    graphql`
        query {
            allBoardgamesJson {
                edges {
                    node {
                        title
                        playTime
                        players
                        bggRating
                        weight
                        own
                        played
                        expansions
                    }
                }
            }
        }
    `,
  );

  const games = data.allBoardgamesJson.edges.map(game => {
    return game.node
  });

  const gameWantCheck = (game) => {
    if (!game.own) {
      return (<GameGridCard gameInfo={game}/>)
    }
  };

  const gameOwnCheck = (game) => {
    if (game.own && !game.played) {
      return (<GameGridCard gameInfo={game}/>)
    }
  };

  const gamePlayedCheck = (game) => {
    if (game.own && game.played) {
      return (<GameGridCard gameInfo={game}/>)
    }
  };

  return (
    <Layout>
      <SiteHelmet title="Board Games"/>
      <GameGridLayout>
        <Grid item xs>
          <GameGridTitle title={"Want to Buy"} />
          {
            games.map(game => gameWantCheck(game))
          }
        </Grid>
        <Grid item xs>
          <GameGridTitle title={"Want to play"} />
          {
            games.map(game => gameOwnCheck(game))
          }
        </Grid>
        <Grid item xs>
          <GameGridTitle title={"Have Played"} />
          {
            games.map(game => gamePlayedCheck(game))
          }
        </Grid>
      </GameGridLayout>
    </Layout>
  );
};

export default Boardgames;